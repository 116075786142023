import React from 'react';
import { user } from '@kespa/validators';
import Form from './Form';
import skipSSR from './skipSSR';

export const signupFields = [
  {
    name: 'firstName',
    label: 'Имя',
  },
  {
    name: 'lastName',
    label: 'Фамилия',
  },
  {
    name: 'email',
    label: 'Почта',
  },
  {
    name: 'skype',
    label: 'Skype',
  },
  {
    name: 'phone',
    label: 'Телефон',
    type: 'tel',
  },
];

const signupSuccessMessage = () => (
  <div className="confirmation-message">
    <p className="fa-wrapper">
      <i className="fa fa-check" />
    </p>
    <p>
      Заявка получена! Мы свяжемся с&nbsp;вами по указанному телефону
      и&nbsp;согласуем время пробного урока.
    </p>
  </div>
);

const Signup = () => (
  <div>
    <Form
      submitUrl="/auth/signup"
      fields={signupFields}
      form="signup"
      validate={user.validate}
      successMessage={signupSuccessMessage}
      // onSubmitSuccess={(result) => setTimeout(() => window.location.href = __CLASSROOM_URL__, 1000)}
    />
  </div>
);

export default skipSSR(Signup);
