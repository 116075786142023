import React, { Component } from 'react';
import Helmet from 'react-helmet';
import axios from 'axios';

import { user } from '@kespa/validators';
import Form from '../components/Form';
import Image from '../components/Image';
import wrapInSection from '../components/wrapInSection';
import wrapInLayout from '../components/wrapInLayout';
import Loading from '../components/Loading';
import FormFailMessage from '../components/FormFailMessage';
import { signupFields } from '../components/Signup';
import { getTokenFromLocation } from '../helpers';

const inviteSignupSuccessMessage = () => {
  window.location = window.location.href = process.env.GATSBY_CLASSROOM_URL;
  //   (
  //   <div className="confirmation-message">
  //     <p className="fa-wrapper">
  //       <i className="fa fa-check" />
  //     </p>
  //     <p>
  //       Спасибо! Мы согласуем расписание с преподавателем и в ближайшее время сообщим вам дату вашего
  //       первого занятия.
  //     </p>
  //   </div>
  // )
};

let emailIndex;
const inviteFields = signupFields.map((field, i) => {
  if (field.name === 'email') {
    emailIndex = i;
    return {
      ...field,
      type: 'hidden',
    };
  }

  return field;
});

inviteFields.splice(emailIndex + 1, 0, {
  name: 'password',
  label: 'Пароль',
  type: 'password',
});

// inviteFields.push({
//   name: 'bio',
//   label: 'Пожалуйста, укажите удобное для вас время занятий. Например, "По средам в 19:30" или "По будням в любое время с 19 до 21". Не забудьте указать ваш часовой пояс.',
//   textarea: true,
//   extraInputProps: {
//     rows: 5,
//   },
// });

const decoratedValidator = (...args) => {
  const result = user.validate(...args) || {};

  // const u = args[0];
  // if (!u.bio || u.bio.trim().length === 0) {
  //   result.bio = 'Пожалуйста, заполните это поле';
  // }
  return Object.keys(result).length === 0 ? null : result;
};

const InviteSignup = ({ token, email }) => (
  <div>
    <p className="invitation-email">{email}</p>
    <Form
      submitUrl={`/invitations/redeem?token=${token}`}
      fields={inviteFields}
      form="signup"
      validate={decoratedValidator}
      initialValues={{ email }}
      successMessage={inviteSignupSuccessMessage}
    />
  </div>
);

class Invite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      error: false,
    };
  }

  componentDidMount() {
    const token = getTokenFromLocation(this.props.location);

    axios
      .get(`/api/invitations?token=${token}`)
      .then(({ data }) => {
        this.setState({ data });
      })
      .catch(error => {
        let errorMessage;
        try {
          errorMessage = error.response.data.errors[0].message;
        } finally {
          this.setState({
            errorMessage,
            error: true,
          });
        }
      });
  }

  render() {
    const { data } = this.state;
    let component;

    if (this.state.error) {
      component = <FormFailMessage message={this.state.errorMessage} />;
    } else if (data) {
      component = <InviteSignup {...data} />;
    } else {
      component = <Loading />;
    }

    return (
      <div className="page-wrapper">
        <Helmet title="КЭСПА – Регистрация по приглашению" />
        <div className="row">
          <div className="col-md-4 col-sm-offset-1">
            <h2>Регистрация по приглашению</h2>
            {component}
          </div>
          <div className="col-md-6 col-sm-offset-1">
            <Image
              src="astro_girl_circle.png"
              className="astro-girl-image"
              alt="Per aspera ad astra!"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default wrapInLayout(wrapInSection(Invite));
